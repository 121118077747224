// App.js
import React, { Suspense } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { CSpinner } from '@coreui/react';
import './scss/style.scss';
import './scss/_custom.scss';

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

const App = () => {

  return (
    <div data-testid="app-root">
      <HashRouter>
        <Suspense
          fallback={
            <div className="pt-3 text-center">
              <CSpinner color="primary" variant="grow" />
            </div>
          }
        >
          <Routes>
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </HashRouter>
    </div>
  );
};

export default App;
